import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./assets/css/style.css";
import "./utils/filter";
import "./utils/directive";
import "./utils/flexible";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "wowjs/css/libs/animate.css";
import scroll from "vue-seamless-scroll";
import * as echarts from "echarts";
import VueParticles from "vue-particles";
import "echarts-liquidfill";
import "echarts-wordcloud";
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.prototype.$imgOnlineUrl = "https://data.darsing.net/images/pc";
// cesium地图key
Vue.prototype.$cesiumKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNGIzMTQ1My01OGFkLTQwODMtYjdlZC1jNTFmZjI3NDRkYjUiLCJpZCI6MTI4Nzg5LCJpYXQiOjE2Nzg4NTc5NDR9.kwIKR4Fd9_R9JoeTWTSseOB9vCWUTt1IrZ644r5JcZY";
// baidu地图key
// Vue.prototype.$baiduKey = "DdoriXIsT3E9K9dErXJre7mhw0kW85VI";
Vue.prototype.$solscanKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODM4NjU2MjUyMzUsImVtYWlsIjoieW15bnlnQDE2My5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODM4NjU2MjV9.ct_Uc8guoGWjSzjmzyY6CN7iFBzBDTpq_b_oXT6cXO0";
Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(ElementUI);
Vue.use(scroll);
Vue.use(VueParticles);
// import BaiduMap from "vue-baidu-map";

// Vue.use(BaiduMap, {
//   ak: "DdoriXIsT3E9K9dErXJre7mhw0kW85VI",
// });

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
